
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp, FaBehance, FaGithub } from "react-icons/fa"
import { MdOutlineAlternateEmail, MdCallMade } from "react-icons/md";
import "../styles/letsGo.scss"

export default function LetsGo(){
    return(
        <section className="letsGo">
            <div className="title">
                <h6>Cansado de perder tempo?</h6>
                <a>Vamos trabalhar
                
                <a href="mailto:geral@gaspardange.com" target="_blank" data-replace="juntos!">
                    <span>juntos
                        <div className="item">
                            <MdCallMade
                            className='icon'
                            size={48}
                            color="white" />
                            <MdCallMade
                            className='icon2'
                            size={36}
                            color="white" />
                        </div>
                    </span>
                </a>
                </a>
            </div>
            <div className="aditionalInfo">
                <div className="info">
                    <h6>Informações de contacto</h6>
                    <p>Sinta-se à vontade para entrar em contato
                    comigo a qualquer momento! Seja por email,
                    redes sociais ou por chamada.</p>
                </div>
                <div className="contacts">
                    <div className="contactsContainer">
                        <h6>{/*Contactos Pessoais*/}</h6>
                        <div className="me">
                            <a target="_blank" href="https://www.behance.net/gaspardange">
                                <div className="item">
                                    <FaBehance
                                    className='icon'
                                    size={48}
                                    color="white" />
                                </div>
                            </a>
                            <a target="_blank" href="https://www.github.com/gaspardangee">
                                <div className="item">
                                    <FaGithub
                                    className='icon'
                                    size={48}
                                    color="white" />
                                </div>
                            </a>
                            <a target="_blank" href="https://www.linkedin.com/in/gaspar-dange-b129a01b9">
                            <div className="item">
                                    <FaLinkedin
                                    className='icon'
                                    size={48}
                                    color="white" />
                            </div>
                            </a>
                        </div>
                        <div className="meSecond">
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=+351%20932953701&text=%2AOl%C3%A1+Gaspar%21%2A%0AVim+pelo+site+e+gostaria+de+conversar+um+pouco+consigo.">
                                <div className="item">
                                    <FaWhatsapp
                                    className='icon'
                                    size={48}
                                    color="white" />
                                </div>
                            </a>
                            <a target="_blank" href="mailto:geral@gaspardange.com">
                            <div className="item">
                                    <MdOutlineAlternateEmail
                                    className='icon'
                                    size={48}
                                    color="white" />
                            </div>
                            </a>
                            <a target="_blank" href="http://instagram.com/gaspardangee">
                            <div className="item">
                                    <FaInstagram
                                    className='icon'
                                    size={48}
                                    color="white" />
                            </div>
                            </a>
                            <a target="_blank" href="http://facebook.com/gaspardangee">
                            <div className="item">
                                    <FaFacebook
                                    className='icon'
                                    size={48}
                                    color="white" />
                            </div>
                            </a>
                        </div>
                    </div>
                    {/*<hr />*/}
                    {/*
                    <div className="contactsContainer">
                    <h6>Contactos Pessoais</h6>
                        <div className="ideal">
                            <a target="_blank" href="http://google.com">
                            <div className="item">
                                    <FaInstagram
                                    className='icon'
                                    size={48}
                                    color="white" />
                            </div>
                            </a>
                            <a target="_blank" href="http://google.com">
                            <div className="item">
                                    <FaFacebook
                                    className='icon'
                                    size={48}
                                    color="white" />
                            </div>
                            </a>
                        </div>
                    </div>
                    */}

                </div>

                {/*<div className="contacts">
                    <div className="me">
                        <div className="item">
                            <img src={phone} alt="" />
                        (+351)<b> 932 953 701</b> / (+244) <b>938 974 100</b>
                        </div>
                        <div className="item">
                            <img src={email} alt="" />
                        (+351)<b> 932 953 701</b> / (+244) <b>938 974 100</b>
                        </div>
                        <div className="item">
                            <img src={facebook} alt="" />
                            <img src={instagram} alt="" />
                        (+351)<b> 932 953 701</b> / (+244) <b>938 974 100</b>
                        </div>
                    </div>
                    <hr />
                    <div className="ideal">
                        <div className="item">
                            <img src={facebook} alt="" />
                            <img src={instagram} alt="" />
                        (+351)<b> 932 953 701</b> / (+244) <b>938 974 100</b>
                        </div>
                    </div>
                </div>*/}
            </div>
        </section>
        )
}