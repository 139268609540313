import "../styles/home.scss"

import Welcome from '../components/Welcome'
import Hightlight from '../components/Hightlights'
import Presentation from '../components/Presentation'
import Design from '../components/Design'
import Dev from '../components/Dev'
import LetsGo from "../components/LetsGo"
import Portfolio from "../components/Portfolio"
import Header from "../components/Header"


export default function Home(){
    return(
        <>
        
            <main>
                <Header />
                <div className="welcome">
                    <Welcome />
                    <Hightlight />
                </div>
                    <Presentation />


                <div className="skills">

                <div className="nameSection">
                        <hr />
                            Minhas habilidades
                        <hr />
                    </div>
                    <Design />
                    <Dev />
                </div>
                <Portfolio />
                <LetsGo />

            </main>
        </>
    )
}