import react from "react"
import Header from "./Header"
import diamound from "../assets/SVG/diamound.svg"
import naming from "../assets/SVG/naming.svg"
import "../styles/welcome.scss"

export default function Welcome(){
    return(
        <section className="welcome">
            <aside>
                <h1 className="title">Port <br /> folio</h1>
                <h5 className="subtitle">Gaspar Dange</h5>
                <img src={diamound} className='diamound' alt="" />
            </aside>
            <img src={naming} className='naming' alt="" />
            </section>
        )
}