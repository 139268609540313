import react from "react"
import targetIcon from "../assets/SVG/targetIcon.svg"
import diamoundIcon from "../assets/SVG/diamoundIcon.svg"
import picture from "../assets/PNG/picture2.png"
import "../styles/presentation.scss"

export default function Presentation(){
    return(
        <section className="presentation">
            <div className="name">
                <h5 className="disabled">Não me apresentei...</h5>
                <h1>Gaspar <br /> Dange</h1>
                <h5>Designer & <br /> Programador
                </h5>

                
            </div>
            <aside>
                <h5>Sobre Mim</h5>
                <p>Sou apaixonado por Design, Publicidade e Imagem,
                    então trabalho nisso criando sempre experiências
                    memoráveis! Faço parte do time iDEAL Designer, e
                    tera a chance de ver o que a gente ja fez abaixo.</p>
                    <div className="grid">
                        <div className="card">
                            <img src={diamoundIcon} alt="" />
                            <h6>EXCLUSIVIDADE</h6>
                        </div>
                        <div className="card">
                            <img src={targetIcon} alt="" />
                            <h6>FOCO NA SUA <br /> REALIZAÇÃO</h6>
                        </div>
                    </div>
            </aside>
        </section>
        )
}