
import "../styles/portfolio.scss"
import art1 from "../assets/ARTS/1.webp"
import art2 from "../assets/ARTS/2.webp"
import art3 from "../assets/ARTS/3.webp"
import art4 from "../assets/ARTS/4.webp"
import art5 from "../assets/ARTS/5.webp"
import art6 from "../assets/ARTS/6.webp"
//import art7 from "../assets/ARTS/7.webp"
import art8 from "../assets/ARTS/8.webp"
import art9 from "../assets/ARTS/9.webp"
import art10 from "../assets/ARTS/10.webp"

import art11 from "../assets/ARTS/11.webp"
import art12 from "../assets/ARTS/12.webp"
import art13 from "../assets/ARTS/13.webp"
import art14 from "../assets/ARTS/14.webp"
import art15 from "../assets/ARTS/15.webp"
import art16 from "../assets/ARTS/16.webp"
import art17 from "../assets/ARTS/17.webp"
import art18 from "../assets/ARTS/18.webp"
import art19 from "../assets/ARTS/19.webp"
import art20 from "../assets/ARTS/20.webp"

import art21 from "../assets/ARTS/21.webp"
import art22 from "../assets/ARTS/22.webp"
import art23 from "../assets/ARTS/23.webp"
import art24 from "../assets/ARTS/24.webp"
import art25 from "../assets/ARTS/25.webp"
import art26 from "../assets/ARTS/26.webp"
import art27 from "../assets/ARTS/27.webp"
import art28 from "../assets/ARTS/28.webp"
import art29 from "../assets/ARTS/29.webp"
import art30 from "../assets/ARTS/30.webp"

import art31 from "../assets/ARTS/31.webp"
import art32 from "../assets/ARTS/32.webp"
import art33 from "../assets/ARTS/33.webp"
import art34 from "../assets/ARTS/34.webp"
import art35 from "../assets/ARTS/35.webp"
import art36 from "../assets/ARTS/36.webp"
import art37 from "../assets/ARTS/37.webp"
import art38 from "../assets/ARTS/38.webp"
import art39 from "../assets/ARTS/39.webp"
import art40 from "../assets/ARTS/40.webp"

import art41 from "../assets/ARTS/41.webp"
import art42 from "../assets/ARTS/42.webp"
import art43 from "../assets/ARTS/43.webp"
import art44 from "../assets/ARTS/44.webp"
import art45 from "../assets/ARTS/45.webp"
import art46 from "../assets/ARTS/46.webp"
import art47 from "../assets/ARTS/47.webp"
//import art48 from "../assets/ARTS/48.webp"
import art49 from "../assets/ARTS/49.webp"
import art50 from "../assets/ARTS/50.webp"

import art51 from "../assets/ARTS/51.webp"
import art52 from "../assets/ARTS/52.webp"
import art53 from "../assets/ARTS/53.webp"
import art54 from "../assets/ARTS/54.webp"
import art55 from "../assets/ARTS/55.webp"
import art56 from "../assets/ARTS/56.webp"
import art57 from "../assets/ARTS/57.webp"
import art58 from "../assets/ARTS/58.webp"

export default function Portfolio(){

    const images = [
        art1, art2, art3, art4, art5, art6, art8, art9, art10,

        art11, art12, art13, art14, art15, art16, art17, art18, art19, art20,

        art21, art22, art23, art24, art25, art26, art27, art28, art29, art30,

        art31, art32, art33, art34, art35, art36, art37, art38, art39, art40,

        art41, art42, art43, art44, art45, art46, art47, art49, art50,

        art51, art52, art53, art54, art55, art56, art57, art58,
      ];

    return(
            <section className="portfolio">
                <div className="title">
                    <h3>Veja algumas</h3>
                    <h1>Missões <br /> Cumpridas</h1>
                    <h3 className="disabled">@aidealdesigner</h3>
                </div>

                <aside>
                    <div className="image">
                        {images.map(
                            (name, index) => (
                                <img src={name} key={index} alt="" />
                            )
                        )}
                    </div>
                </aside>
            </section>
        )
}
