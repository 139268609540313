import React, { useState } from "react";
import "../styles/header.scss"
import "../styles/menu.scss"
import Menu from "./Menu";

export default function Header(){
    
    const [toogleValue, setToogleValue] = useState(false)
    function toogleMenu(){
        setToogleValue(!toogleValue)
    }

    return(<>
            <header>
                <nav>
                    <ul>
                        <li>
                            <a href="/">Gaspar Dange</a>
                        </li>
                        <li>
                            <a>
                                <div className={toogleValue ? "toogle active" : "toogle"}
                                onClick={toogleMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                {/*
                                <TbMenu
                                color="#ffffff"
                                size={30} />*/}
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>

        <div  className={toogleValue ? "menu active" : "menu"}>
            <Menu />
        </div>
        </>
    )
}
