import react from "react"
import diamound from "../assets/SVG/diamound.svg"
import target from "../assets/SVG/target.svg"
import "../styles/hightlights.scss"

export default function Hightlight(){
    return(
        <section className="hightlight">
            <h5>SABE O QUE TE FALTA?</h5>
            <aside>
                <h1 className="title">Se <br /> Destacar!</h1>
                <img src={diamound} className='diamound' alt="" />
                <img src={target} className='target' alt="" />
            </aside>
            <p>
            É o que falta em <b className="active">90%</b>
            de todos que têm ideias inovadoras,
            empresas ou empreendimentos.
            Mas você pode simplesmente se juntar aos
            <b className="active">10%</b>!
            </p>
        </section>
        )
}