import React from 'react'
import diamound from "../assets/SVG/diamound.svg"
import target from "../assets/SVG/target.svg"

import js from "../assets/SVG/js.svg"
import jsStar from "../assets/SVG/js-star.svg"
import jsStarOutline from "../assets/SVG/js-star-outline.svg"

import node from "../assets/SVG/node.svg"
import nodeStar from "../assets/SVG/node-star.svg"
import nodeStarOutline from "../assets/SVG/node-star-outline.svg"

import py from "../assets/SVG/py.svg"
import pyStar from "../assets/SVG/py-star.svg"
import pyStarOutline from "../assets/SVG/py-star-outline.svg"

import react from "../assets/SVG/react.svg"
import reactStar from "../assets/SVG/react-star.svg"
import reactStarOutline from "../assets/SVG/react-star-outline.svg"

import rnative from "../assets/SVG/rnative.svg"
import rnativeStar from "../assets/SVG/rnative-star.svg"
import rnativeStarOutline from "../assets/SVG/rnative-star-outline.svg"

import ts from "../assets/SVG/ts.svg"
import tsStar from "../assets/SVG/ts-star.svg"
import tsStarOutline from "../assets/SVG/ts-star-outline.svg"

import vscode from "../assets/SVG/vscode.svg"
import vscodeStar from "../assets/SVG/vscode-star.svg"
import vscodeStarOutline from "../assets/SVG/vscode-star-outline.svg"

import dev from "../assets/PNG/dev.png"
import "../styles/dev.scss"

export default function Dev(){
    return(
        <section className="dev">
            <aside>
                <h2 className="title">Programação</h2>
                <p>Websites e aplicações mobile são as minhas
                    aspirações. Com algumas das tecnologias mais
                    em alta do mercado, construo aplicações bem
                    elaboradas e funcionais.</p>
                
                <h6>Principais Ferramentas</h6>
                <div className="grid">
                    <div className="card">
                        <img src={vscode} alt="" />
                        <div className="info">
                            <h6 className='vscode'>VS Code</h6>
                            <div className="stars">
                            <img src={vscodeStar} alt="" />
                            <img src={vscodeStar} alt="" />
                            <img src={vscodeStar} alt="" />
                            <img src={vscodeStar} alt="" />
                            <img src={vscodeStar} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <img src={js} alt="" />
                        <div className="info">
                            <h6 className='js'>Javascript</h6>
                            <div className="stars">
                            <img src={jsStar} alt="" />
                            <img src={jsStar} alt="" />
                            <img src={jsStar} alt="" />
                            <img src={jsStar} alt="" />
                            <img src={jsStarOutline} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <img src={react} alt="" />
                        <div className="info">
                            <h6 className='react'>React JS</h6>
                            <div className="stars">
                            <img src={reactStar} alt="" />
                            <img src={reactStar} alt="" />
                            <img src={reactStar} alt="" />
                            <img src={reactStar} alt="" />
                            <img src={reactStarOutline} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <img src={rnative} alt="" />
                        <div className="info">
                            <h6 className='rnative'>React Native</h6>
                            <div className="stars">
                            <img src={rnativeStar} alt="" />
                            <img src={rnativeStar} alt="" />
                            <img src={rnativeStar} alt="" />
                            <img src={rnativeStar} alt="" />
                            <img src={rnativeStarOutline} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <img src={ts} alt="" />
                        <div className="info">
                            <h6 className='ts'>Typescript</h6>
                            <div className="stars">
                            <img src={tsStar} alt="" />
                            <img src={tsStar} alt="" />
                            <img src={tsStar} alt="" />
                            <img src={tsStar} alt="" />
                            <img src={tsStarOutline} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <img src={node} alt="" />
                        <div className="info">
                            <h6 className='node'>Node JS</h6>
                            <div className="stars">
                            <img src={nodeStar} alt="" />
                            <img src={nodeStar} alt="" />
                            <img src={nodeStarOutline} alt="" />
                            <img src={nodeStarOutline} alt="" />
                            <img src={nodeStarOutline} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <img src={py} alt="" />
                        <div className="info">
                            <h6 className='py'>Python</h6>
                            <div className="stars">
                            <img src={pyStar} alt="" />
                            <img src={pyStarOutline} alt="" />
                            <img src={pyStarOutline} alt="" />
                            <img src={pyStarOutline} alt="" />
                            <img src={pyStarOutline} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        <img src={dev} alt="" className='picture' />
        </section>
        )
}