import react from "react"
import diamound from "../assets/SVG/diamound.svg"
import target from "../assets/SVG/target.svg"

import design from "../assets/PNG/design.png"

import ai from "../assets/SVG/ai.svg"
import aiStar from "../assets/SVG/ai-star.svg"
import aiStarOutline from "../assets/SVG/ai-star-outline.svg"

import ps from "../assets/SVG/ps.svg"
import psStar from "../assets/SVG/ps-star.svg"
import psStarOutline from "../assets/SVG/ps-star-outline.svg"

import corel from "../assets/SVG/corel.svg"
import corelStar from "../assets/SVG/corel-star.svg"
import corelStarOutline from "../assets/SVG/corel-star-outline.svg"

import xd from "../assets/SVG/xd.svg"
import xdStar from "../assets/SVG/xd-star.svg"
import xdStarOutline from "../assets/SVG/xd-star-outline.svg"

import "../styles/design.scss"

export default function Design(){
    return(
        <section className="design">
            <aside>
                <h2 className="title">Design Gráfico
                <br />& UI UX Design</h2>
                <p>Desde Logotipo e Identidade Visual até
                websites estrategicamente elaborados.
                Isto e muito mais, para tornar a experiência
                com cada marca inesquecível, tendo flyers,
                panfletos, cartões de visita, certificados,
                banners, cartazes, convites, ilustrações e
                muito mais como algumas das artes que sou
                capaz de se produzir.</p>
                
                <h6>Principais Ferramentas</h6>
                <div className="grid">
                    <div className="card">
                        <img src={ai} alt="" />
                        <div className="info">
                            <h6 className='ai'>Illustrator</h6>
                            <div className="stars">
                            <img src={aiStar} alt="" />
                            <img src={aiStar} alt="" />
                            <img src={aiStar} alt="" />
                            <img src={aiStar} alt="" />
                            <img src={aiStarOutline} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <img src={ps} alt="" />
                        <div className="info">
                            <h6 className='ps'>Photoshop</h6>
                            <div className="stars">
                            <img src={psStar} alt="" />
                            <img src={psStar} alt="" />
                            <img src={psStar} alt="" />
                            <img src={psStar} alt="" />
                            <img src={psStarOutline} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <img src={xd} alt="" />
                        <div className="info">
                            <h6 className='xd'>Adobe XD</h6>
                            <div className="stars">
                            <img src={xdStar} alt="" />
                            <img src={xdStar} alt="" />
                            <img src={xdStar} alt="" />
                            <img src={xdStar} alt="" />
                            <img src={xdStarOutline} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <img src={corel} alt="" />
                        <div className="info">
                            <h6 className='corel'>Corel Draw</h6>
                            <div className="stars">
                            <img src={corelStar} alt="" />
                            <img src={corelStar} alt="" />
                            <img src={corelStar} alt="" />
                            <img src={corelStarOutline} alt="" />
                            <img src={corelStarOutline} alt="" />
                            </div>
                        </div>
                    </div>
                    
                
                </div>
            </aside>
        <img src={design} alt="" className='picture' />
        </section>
        )
}